define("discourse/plugins/discourse-assign/discourse/templates/connectors/bread-crumbs-right/assign-boolean-filter", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li>
    <ComboBox
      @content={{this.statuses}}
      @value={{this.status}}
      @valueProperty="value"
      @options={{hash caretDownIcon="caret-right" caretUpIcon="caret-down"}}
      @onChange={{this.changeStatus}}
      class="assign-boolean-filter"
    />
  </li>
  */
  {
    "id": "2kMA17DD",
    "block": "[[[10,\"li\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"assign-boolean-filter\"]],[[\"@content\",\"@value\",\"@valueProperty\",\"@options\",\"@onChange\"],[[30,0,[\"statuses\"]],[30,0,[\"status\"]],\"value\",[28,[37,1],null,[[\"caretDownIcon\",\"caretUpIcon\"],[\"caret-right\",\"caret-down\"]]],[30,0,[\"changeStatus\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"combo-box\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-assign/discourse/templates/connectors/bread-crumbs-right/assign-boolean-filter.hbs",
    "isStrictMode": false
  });
});