define("discourse/plugins/discourse-assign/discourse/services/topic-internal-link", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicInternalLink extends _service.default {
    create(link) {
      return (0, _ajax.ajax)("/assign/topic/add-internal-link", {
        method: "POST",
        data: link
      }).then(res => {}).catch(e => {
        (0, _ajaxError.popupAjaxError)(e);
      });
    }
    delete(id) {
      return (0, _ajax.ajax)(`/assign/topic-internal-links/${id}`, {
        method: "DELETE"
      }).then(res => {}).catch(err => {
        (0, _ajaxError.popupAjaxError)(err);
      });
    }
  }
  _exports.default = TopicInternalLink;
});