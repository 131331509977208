define("discourse/plugins/discourse-assign/discourse/components/modal/add-internal-link", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal class="assign" @title={{this.title}} @closeModal={{@closeModal}}>
    <:body>
      <div class="control-group">
        <label>{{i18n "internal_link.add.form.title_label"}}</label>
        <Input
          @value={{this.link.title}}
          maxlength="150"
          required
         />
  
        {{#if this.showTitleEmptyError}}
          <span class="error-label">
            {{icon "exclamation-triangle"}}
            {{i18n "discourse_assign.assign_modal.choose_assignee"}}
          </span>
        {{/if}}
      </div>
      <div class="control-group">
        <label>{{i18n "internal_link.add.form.url_label"}}</label>
        <Input
          @value={{this.link.url}}
          @type="url"
          required
          style="width: 100%"
          />
  
        {{#if this.showUrlEmptyError}}
          <span class="error-label">
            {{icon "exclamation-triangle"}}
            {{i18n "discourse_assign.assign_modal.choose_assignee"}}
          </span>
        {{/if}}
      </div>
    </:body>
  
    <:footer>
      <DButton
        class="btn-primary"
        @action={{this.submit}}
        @label={{"internal_link.add.button"}}
      />
  
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "Cc0aicPA",
    "block": "[[[8,[39,0],[[24,0,\"assign\"]],[[\"@title\",\"@closeModal\"],[[30,0,[\"title\"]],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,1],[\"internal_link.add.form.title_label\"],null]],[13],[1,\"\\n      \"],[8,[39,2],[[24,\"maxlength\",\"150\"],[24,\"required\",\"\"]],[[\"@value\"],[[30,0,[\"link\",\"title\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showTitleEmptyError\"]],[[[1,\"        \"],[10,1],[14,0,\"error-label\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"exclamation-triangle\"],null]],[1,\"\\n          \"],[1,[28,[35,1],[\"discourse_assign.assign_modal.choose_assignee\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,1],[\"internal_link.add.form.url_label\"],null]],[13],[1,\"\\n      \"],[8,[39,2],[[24,\"required\",\"\"],[24,5,\"width: 100%\"]],[[\"@value\",\"@type\"],[[30,0,[\"link\",\"url\"]],\"url\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showUrlEmptyError\"]],[[[1,\"        \"],[10,1],[14,0,\"error-label\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"exclamation-triangle\"],null]],[1,\"\\n          \"],[1,[28,[35,1],[\"discourse_assign.assign_modal.choose_assignee\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"submit\"]],\"internal_link.add.button\"]],null],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"input\",\"if\",\"icon\",\"d-button\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-assign/discourse/components/modal/add-internal-link.hbs",
    "isStrictMode": false
  });
  class AddInternalLink extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topicInternalLink", [_service.inject]))();
    #topicInternalLink = (() => (dt7948.i(this, "topicInternalLink"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "link", [_tracking.tracked], function () {
      return {};
    }))();
    #link = (() => (dt7948.i(this, "link"), void 0))();
    get title() {
      return _I18n.default.t(`internal_link.add.title`);
    }
    get showTitleEmptyError() {
      return this.link.title;
    }
    get showUrlEmptyError() {
      return this.link.url;
    }
    async submit() {
      this.link.topic_id = this.args.model.post.topic.id;
      let topic = this.args.model.post.topic;
      await this.topicInternalLink.create(this.link);
      this.appEvents.trigger("post-stream:refresh", {
        id: topic.postStream.posts[0].id
      });
      this.args.closeModal();
    }
    static #_5 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
  }
  _exports.default = AddInternalLink;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddInternalLink);
});